<template>
  <v-container fluid class="pa-0" v-if="getProduct">
    <banner></banner>
    <v-col cols="12">
      <v-breadcrumbs
        :items="itemBreadCrumbs"
        large
        class="josefin-light p product_breadcrumb"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :exact="true">
            {{ $t(item.text) }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-container class="product-detail">
      <v-row class="px-xl-16">
        <v-col cols="12" lg="5">
          <v-carousel
            :show-arrows="false"
            hide-delimiter-background
            delimiter-icon="mdi-circle"
            class="carrousel2"
          >
            <v-carousel-item
              v-for="(item, index) in getProduct.images"
              :key="index"
              :src="item.medium_image_url"
            >
              <div v-if="!getProduct.in_stock" class="unavailable px-10 py-1">
                <p
                  class="
                    josefin-light
                    white-black
                    text-lg-justify text-center
                    ma-0
                    mr-n3
                  "
                >
                  <span class="josefin-italic">{{
                    $t("productHome.unavailable")
                  }}</span>
                </p>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" lg="7" class="align-center d-flex">
          <v-row class="px-lg-16 px-10">
            <v-col cols="12" class="pa-0">
              <h1
                class="copperplate-medium gold h1 my-3 text-center text-lg-left"
                v-html="getProduct.short_description"
              ></h1>
            </v-col>

            <h1
              class="h4 black-grin mt-n3 pb-3 text-lg-justify text-center"
              v-html="getProduct.name"
            ></h1>

            <p
              class="josefin-light black-grin p text-lg-justify text-center"
              v-html="getProduct.description"
            ></p>
            <p class="josefin-light black-grin p text-lg-justify text-center">
              {{$t("productHome.productDetails")}}
            </p>
            <v-row class="px-3 pt-5 justify-center justify-lg-start">
              <v-col cols="12" lg="6">
                <v-row class="justify-lg-start justify-center">
                  <h1
                    class="
                      copperplate-medium
                      black-grin
                      h1
                      text-center text-lg-left
                    "
                  >
                    RD$
                    {{ Intl.NumberFormat().format(getProduct.price) + ".00" }}
                  </h1>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6">
                <v-row class="justify-lg-end justify-center">
                  <v-btn
                    class="btn_second product_detail_btn mb-10"
                    elevation="0"
                    medium
                    :disabled="!getProduct.in_stock || !getUser"
                    @click="addProductToCart(getProduct.id)"
                    >{{ $t("productHome.btnBuy") }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        class="justify-center container-details py-10 px-xl-16"
        v-if="haveAdditionalInfo"
      >
        <v-col cols="11" lg="4" class="mr-lg-5 details-border">
          <v-row class="px-5 dark">
            <h1 class="h3 gold">{{ $t("productHome.details") }}</h1>
          </v-row>
          <div>
            <v-row class="pa-5 py-10">
              <v-col cols="3">
                <div
                  class="
                    rounded-circle
                    icon-details
                    dark
                    justify-center
                    d-flex
                    align-center
                  "
                >
                  <v-img
                    contain
                    height="34"
                    width="16"
                    src="/icon/botella_icon.svg"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="9">
                <div>
                  <v-row class="ma-0">
                    <h2
                      class="h2 gold ma-0"
                      v-html="getAditionalInformationByName('contenido').label"
                    ></h2>
                  </v-row>
                  <v-row class="ma-0">
                    <p
                      class="h2 ma-0"
                      v-html="getAditionalInformationByName('contenido').value"
                    ></p>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-5">
              <v-col cols="3">
                <div
                  class="
                    rounded-circle
                    icon-details
                    dark
                    justify-center
                    d-flex
                    align-center
                  "
                >
                  <v-img
                    contain
                    height="25"
                    width="16"
                    src="/icon/porcentaje_icon.svg"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="9">
                <div>
                  <v-row class="ma-0">
                    <h2
                      class="h2 gold ma-0"
                      v-html="
                        getAditionalInformationByName('gradoAlcohol').label
                      "
                    ></h2>
                  </v-row>
                  <v-row class="ma-0">
                    <p
                      class="h2 ma-0"
                      v-html="
                        getAditionalInformationByName('gradoAlcohol').value
                      "
                    ></p>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="11"
          lg="7"
          class="ml-lg-5 mt-5 mt-lg-0 details-border"
          v-if="haveAdditionalInfo"
        >
          <v-row class="px-5 dark">
            <h1 class="h3 gold">{{ $t("productHome.tastingNotes") }}</h1>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-row class="pa-5">
                <v-col cols="3">
                  <div
                    class="
                      rounded-circle
                      icon-details
                      dark
                      justify-center
                      d-flex
                      align-center
                    "
                  >
                    <v-img
                      contain
                      height="20"
                      width="16"
                      src="/icon/color_icon.svg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <v-row class="ma-0">
                      <h2
                        class="h2 gold ma-0"
                        v-html="getAditionalInformationByName('color').label"
                      ></h2>
                    </v-row>
                    <v-row class="ma-0">
                      <p
                        class="p josefin-light ma-0"
                        v-html="getAditionalInformationByName('color').value"
                      ></p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6">
              <v-row class="pa-5">
                <v-col cols="3">
                  <div
                    class="
                      rounded-circle
                      icon-details
                      dark
                      justify-center
                      d-flex
                      align-center
                    "
                  >
                    <v-img
                      contain
                      height="20"
                      width="16"
                      src="/icon/sabor_icon.svg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <v-row class="ma-0">
                      <h2
                        class="h2 gold ma-0"
                        v-html="getAditionalInformationByName('sabor').label"
                      ></h2>
                    </v-row>
                    <v-row class="ma-0">
                      <p
                        class="p josefin-light ma-0"
                        v-html="getAditionalInformationByName('sabor').value"
                      ></p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6">
              <v-row class="pa-5">
                <v-col cols="3">
                  <div
                    class="
                      rounded-circle
                      icon-details
                      dark
                      justify-center
                      d-flex
                      align-center
                    "
                  >
                    <v-img
                      contain
                      height="20"
                      width="16"
                      src="/icon/aroma_icon.svg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <v-row class="ma-0">
                      <h2
                        class="h2 gold ma-0"
                        v-html="getAditionalInformationByName('aroma').label"
                      ></h2>
                    </v-row>
                    <v-row class="ma-0">
                      <p
                        class="p josefin-light ma-0"
                        v-html="getAditionalInformationByName('aroma').value"
                      ></p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6">
              <v-row v-if="false" class="pa-5">
                <v-col cols="3">
                  <div
                    class="
                      rounded-circle
                      icon-details
                      dark
                      justify-center
                      d-flex
                      align-center
                    "
                  >
                    <v-img
                      contain
                      height="20"
                      width="16"
                      src="/icon/postgusto_icon.svg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <v-row class="ma-0">
                      <h2 class="h2 gold ma-0">POSTGUSTO</h2>
                    </v-row>
                    <v-row class="ma-0">
                      <p class="p josefin-light ma-0">
                        {{ getProduct.postgusto }}
                      </p>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  async created() {
    await this.fetchGetProductBySlug(this.$route.params.slug);
  },
  computed: {
    ...mapGetters("products", ["getProduct"]),
    ...mapGetters("customer", ["getUser"]),
    itemBreadCrumbs() {
      return [
        {
          text: "menu.home",
          to: {
            name: "home",
          },
        },
        {
          text: "productHome.title",
          to: {
            name: "collection-products",
          },
        },
        {
          text: this.getProduct.name,
          to: {
            name: "product-detail",
          },
        },
      ];
    },
    haveAdditionalInfo() {
      return this.getProduct.additionalInformation?.length > 1;
    }
  },
  methods: {
    ...mapActions("products", ["fetchGetProductBySlug"]),
    ...mapActions("shoppingCart", ["addProductInCart"]),
    getAditionalInformationByName(name) {
      return this.getProduct.additionalInformation.find(
        (info) => info.code === name
      );
    },
    async addProductToCart(productId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6",
        },
        buttonsStyling: false,
      });
      try {
        await this.addProductInCart(productId);
        swalWithBootstrapButtons
          .fire({
            title: "¡Producto Agregado exitosamente!",
            text: "Producto Agregado exitosamente!",
            confirmButtonText: "Aceptar",
            icon: "success",
          })
          .then(() => this.$router.go());
      } catch (error) {
        console.log(error);
        swalWithBootstrapButtons.fire({
          title: "¡Producto Agotado!",
          text: "Intente más tarde",
          confirmButtonText: "Aceptar",
          icon: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.unavailable {
  background: rgba(224, 32, 32, 1);
  position: absolute;
  transform: rotate(45deg);
  top: 20px;
  left: 70%;
  clip-path: polygon(8% 100%, 31% 0, 78% 0, 100% 100%);
}
.row-unavailable {
  position: absolute;
}
</style>
